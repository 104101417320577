const Billing = () => import(/* webpackChunkName: "billing-index" */ '@/pages/billing/index')
const BillingDashboard = () => import(/* webpackChunkName: "billing-dashboard" */ '@/pages/billing/dashboard')

const Invoices = () => import(/* webpackChunkName: "billing-invoices" */ '@/pages/billing/invoices/index')
const PayInvoice = () => import(/* webpackChunkName: "billing-pay-invoice" */ '@/pages/billing/invoices/pay')
const ListInvoices = () => import(/* webpackChunkName: "list-invoices" */ '@/pages/billing/invoices/list')

const Transactions = () => import(/* webpackChunkName: "billing-transactions" */ '@/pages/billing/transactions/index')
const ListTransactions = () => import(/* webpackChunkName: "list-transactions" */ '@/pages/billing/transactions/list')

const CreditPayIn = () => import(/* webpackChunkName: "credit-pay-in" */ '@/pages/billing/credit/payIn')

export default [
    {
        path: '/billing',
        component: Billing,
        meta: {
            breadcrumb: 'breadcrumb.billing.title',
            breadcrumb_to: {name: 'billing.dashboard' }
        },
        children: [
            {path: '', redirect: {name: 'billing.dashboard'}},
            {
                path: 'dashboard',
                name: 'billing.dashboard',
                component: BillingDashboard,
                meta: {
                    breadcrumb: 'breadcrumb.billing.dashboard'
                }
            },
            {
                path: 'invoices',
                component: Invoices,
                meta: {
                    breadcrumb: 'breadcrumb.billing.invoices.title',
                    breadcrumb_to: {name: 'billing.invoices.list' }
                },
                children: [
                    {
                        path: 'list',
                        name: 'billing.invoices.list',
                        component: ListInvoices,
                        meta: {
                            breadcrumb: 'breadcrumb.billing.invoices.list'
                        }
                    },
                ]
            },
            {
                path: 'transactions',
                component: Transactions,
                meta: {
                    breadcrumb: 'breadcrumb.billing.transactions.title',
                    breadcrumb_to: {name: 'billing.transactions.list' }
                },
                children: [
                    {
                        path: 'list',
                        name: 'billing.transactions.list',
                        component: ListTransactions,
                        meta: {
                            breadcrumb: 'breadcrumb.billing.transactions.list'
                        }
                    },
                ]
            },
        ]
    },
    {
        path: '/billing/credit/pay-in',
        name: 'billing.credit.payIn',
        component: CreditPayIn,
        meta: {
            breadcrumb: 'breadcrumb.billing.credit.top-up'
        }
    },
    {
        path: '/billing/invoices/:invoice_id/pay/:payment_method?',
        name: 'billing.invoices.pay',
        component: PayInvoice,
        meta: {
            breadcrumb: 'breadcrumb.billing.invoices.pay'
        }
    },
]
