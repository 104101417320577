import * as types from '../mutation-types'
import axios from 'axios'

// state
export const state = {
  contracts: []
}

// getters
export const getters = {
  contracts: state => state.contracts
}

// mutations
export const mutations = {
  [types.SET_CONTRACTS] (state, payload) {
    state.contracts = payload
  }
}

// actions
export const actions = {
  async get ({ commit }) {
    const response = await axios.get('contracts')
    commit(types.SET_CONTRACTS, response.data.data)
  }
}
