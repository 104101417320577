<template>
    <v-dialog v-model="isOpen"
              persistent
              fullscreen
              transition="fade-transition"
              content-class="px-4"
              :dark="false"
    >
        <v-layout class="d-flex justify-center" style="min-height: calc(100vh - 50px)">
            <div class="d-flex flex-column" style="min-height: 90vh">
                <div class="d-flex justify-center pb-8 pt-11">
                    <img :src="lay7LogoSrc" alt="LAY7 logo" style="height: 50px;">
                </div>

                <v-spacer/>

                <div class="text-center">
                    <h1>Login</h1>
                    <login-form ref="form"
                                @logged-in="onLoggedIn"
                    ></login-form>
                    <p class="mt-8">
                        <router-link :to="{name: 'password.forgot'}">{{ $t('Forgot password?') }}</router-link>

                        <span class="mx-2">|</span>

                        <router-link :to="{name: 'register'}">{{ $t('Create account') }}</router-link>
                    </p>
                </div>

                <v-spacer/>

                <div class="register-bottom-bar pt-8">
                    <v-row no-gutters>
                        <v-col cols="12">
                            <span class="copyright-text">Copyright © {{ year }} LAY7 cloud</span>
                        </v-col>
                        <v-col cols="12">
                            <div class="links-right">
                                <a :href="websiteUrl()" target="_blank">{{ $t('Homepage') }}</a>
                                <a :href="websiteUrl('terms')" target="_blank">{{ $t('Terms') }}</a>
                                <a :href="websiteUrl('privacy')" target="_blank">{{ $t('Privacy') }}</a>
                                <a :href="websiteUrl('imprint')" target="_blank">{{ $t('Imprint') }}</a>
                            </div>
                        </v-col>
                    </v-row>
                </div>

            </div>
        </v-layout>
    </v-dialog>
</template>

<script>
    import LoginForm from "@/forms/LoginForm";
    import {mapGetters} from "vuex";
    import UrlBuilder from "@/mixins/UrlBuilder";
    import Utils from "@/mixins/Utils";
    export default {
        name: "login-dialog",

        mixins: [
            UrlBuilder,
            Utils
        ],

        components: {LoginForm},

        computed: {
            ...mapGetters({
                darkMode: "ui/darkMode"
            }),
            year() {
                return new Date().getFullYear()
            },
        },
        data: () => ({
            isOpen: false,
            form: {},
            loggedInCallback: () => (null)
        }),

        methods: {
            open() {
                this.isOpen = true
                return this
            },
            close() {
                this.isOpen = false
                return this
            },
            onLoggedIn() {
                this.loggedInCallback()
                this.isOpen = false
            },
            loggedIn(val) {
                this.loggedInCallback = val
                return this
            },
        }
    }
</script>
