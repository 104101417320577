<template>
    <div style="height: 100%">
        <app-header>
            <template #logo>
                <router-link :to="{name: 'dashboard'}">
                    <img height="30"
                         width="183"
                         src="../assets/logo-367-60.png"
                         alt="LAY 7"
                         style="height:30px;margin-top:5px"
                    >
                </router-link>
            </template>

            <template #header-right>
                <user-nav />
                <locale-dropdown />
            </template>
        </app-header>

        <v-main style="height: 100%">
            <router-view />
        </v-main>

    </div>
</template>

<script>
import AppHeader from "@/components/AppHeader";
import UserNav from "@/navs/user/nav";
import LocaleDropdown from "@/components/LocaleDropdown";

export default {
    name: "default-layout",

    components: {
        LocaleDropdown,
        UserNav,
        AppHeader
    },
}
</script>
