import * as types from '../mutation-types'

// state
export const state = {
  projects: {},
  projectsForSelect: [],
  currentProject: null
}

// getters
export const getters = {
  projects: state => state.projects,
  projectsForSelect: state => state.projectsForSelect,
  currentSpace: state => state.currentProject
}

// mutations
export const mutations = {
  [types.SET_PROJECTS] (state, payload) {
    state.projects = payload
  },
  [types.SET_PROJECTS_FOR_SELECT] (state, payload) {
    state.projectsForSelect = payload
  },
  [types.SET_CURRENT_PROJECT] (state, payload) {
    state.currentProject = payload
  }
}

// actions
export const actions = {
  setCurrentProject ({ commit }, payload) {
    commit(types.SET_CURRENT_PROJECT, payload)
  },
  async loadProjects({commit}) {
    const response = await axios.get('projects')
    commit(types.SET_PROJECTS, response.data.data)
  },
  async loadProjectsForSelect({commit}) {
    const response = await axios.get('projects')
    commit(types.SET_PROJECTS_FOR_SELECT, _.map(response.data.data, p => ({label: p.name, value: p.id})))
  }

}
