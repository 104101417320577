const Register = () => import(/* webpackChunkName: "register" */ '@/pages/auth/register')
const EmailVerify = () => import(/* webpackChunkName: "email.verify" */ '@/pages/auth/email/verify')
const PasswordForgot = () => import(/* webpackChunkName: "password.forgot" */ '@/pages/auth/password/forgot')
const PasswordReset = () => import(/* webpackChunkName: "password.reset" */ '@/pages/auth/password/reset')

export default [
    {
        path: '/login',
        name: "login",
        redirect: {name: 'dashboard'},
    },
    {
        path: '/register',
        name: "register",
        component: Register,
        meta: {
            auth: false,
            layout: "guest-centered"
        },
    },
    {
        path: '/email/verify/:id/:hash',
        name: "email.verify",
        component: EmailVerify,
        meta: {
            auth: false,
            layout: "guest-centered"
        },
    },
    {
        path: '/password/forgot',
        name: "password.forgot",
        component: PasswordForgot,
        meta: {
            auth: false,
            layout: "guest-centered"
        },
    },
    {
        path: '/password/reset/:token',
        name: "password.reset",
        component: PasswordReset,
        meta: {
            auth: false,
            layout: "guest-centered"
        },
    },
]
