<template>
    <div>
        <v-app-bar class="app-header" app clipped-left dense extension-height="36" >
            <slot name="nav-icon">
                <v-app-bar-nav-icon @click="toggleMainNav"></v-app-bar-nav-icon>
            </slot>

            <v-toolbar-title>
                <slot name="logo"></slot>
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <slot name="header-right"></slot>

            <template v-slot:extension v-if="$breadcrumbs.length > 0">
                <slot name="header-extension">
                    <v-breadcrumbs :items="$breadcrumbs" style="padding: 0 !important;" >
                        <template v-slot:divider>
                            >
                        </template>

                        <template v-slot:item="{item}">
                            <v-breadcrumbs-item :disabled="$breadcrumbs.indexOf(item) === ($breadcrumbs.length -1)"
                                                :to="getRoute(item)"
                                                exact
                            >
                                {{ $t(item.meta.breadcrumb, {...$route.params}) }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                </slot>
            </template>
        </v-app-bar>

        <v-navigation-drawer v-model="mainNavOpen" app temporary dense>
            <slot name="nav">
                <main-nav></main-nav>
            </slot>
        </v-navigation-drawer>
    </div>
</template>
<script>
    import {mapGetters, mapActions} from 'vuex'
    import MainNav from "../navs/main/nav";
    import DefaultContainer from "../containers/DefaultContainer";

    export default {
        name: "app-header",

        components: {
            DefaultContainer,
            MainNav
        },

        data: () => ({
            items: [
                {
                    text: 'Dashboard',
                    disabled: false,
                    href: 'breadcrumbs_dashboard',
                },
                {
                    text: 'Link 1',
                    disabled: false,
                    href: 'breadcrumbs_link_1',
                },
                {
                    text: 'Link 2',
                    disabled: true,
                    href: 'breadcrumbs_link_2',
                },
            ]
        }),

        computed: {
            ...mapGetters({
                getDarkMode: "ui/darkMode",
                getMainNavOpen: "ui/mainNavOpen",
            }),
            mainNavOpen: {
                get() {
                    return this.getMainNavOpen
                },
                set(value) {
                    return this.setMainNavOpen(value)
                }
            },
            darkMode: {
                get() {
                    return this.getDarkMode
                },
                set() {

                }
            }
        },

        methods: {
            ...mapActions({
                toggleMainNav: "ui/toggleMainNavOpen",
                setMainNavOpen: "ui/setMainNavOpen",
            }),
            getRoute(item)
            {
                if (item.hasOwnProperty('meta') && item.meta instanceof Object)
                {
                    if (item.meta.hasOwnProperty('breadcrumb_to'))
                    {
                        return item.meta.breadcrumb_to
                    }
                }

                if (item.hasOwnProperty('redirect') && item.redirect)
                {
                    if (item.redirect.hasOwnProperty('name'))
                    {
                        return {name: item.redirect.name}
                    }

                    if (item.redirect instanceof String)
                    {
                        return item.redirect
                    }
                }

                if (item.hasOwnProperty('name') && item.name)
                {
                    return {name: item.name}
                }

                return '/' + item.path
            }
        }
    }
</script>

<style>
    .v-btn--fab.v-size--default .v-icon, .v-btn--fab.v-size--small .v-icon, .v-btn--icon.v-size--default .v-icon, .v-btn--icon.v-size--small .v-icon {
        height: 32px;
        font-size: 32px;
        width: 32px;
    }

    .app-header > .v-toolbar__content {
        background-color: #000 !important;
        color: #fff
    }

    .v-toolbar__content > .v-btn--outlined .v-btn__content .v-icon,
    .v-toolbar__content > .v-btn--round .v-btn__content .v-icon,
    .v-toolbar__content > .v-btn.primary--text {
        color: #fff !important;
    }

</style>
