export default {
    computed: {
        darkMode() {
            return this.$store.state.ui.darkMode
        },
        lay7LogoSrc() {
            return this.darkMode ? '/l7-logo-white.png' : '/l7-logo-black.png'
        }
    }
}
