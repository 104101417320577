<template>
    <v-menu
            left
            bottom
    >
        <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" color="white">
                <span :class="'mr-2 fi fi-' + getFlag(currentLocale)"></span> {{ currentLocale }}
            </v-btn>
        </template>

        <v-list>
            <template v-for="locale in locales">
                <v-list-item v-if="locale !== currentLocale"
                             @click.prevent="updateLocale(locale)"
                             class="text-uppercase"
                >
                    <span :class="'mr-2 fi fi-' + getFlag(locale)"></span> {{ locale }}
                </v-list-item>
            </template>
        </v-list>
    </v-menu>
</template>

<script>

    export default {
        computed: {
            currentLocale: {
                get() {
                    return this.$root.$i18n.locale
                },
                set(locale) {
                    this.$root.$i18n.locale = locale
                }
            },
            locales() {
                return [
                    'de',
                    'en'
                ]
            }
        },

        methods: {
            getFlag(lang) {
                switch (lang) {
                    case "de":
                        return 'de';
                    case "en":
                        return 'gb';
                    default:
                        return lang
                }
            },
            async updateLocale(locale) {
                this.$setLocale(locale)
            }
        }
    }
</script>
