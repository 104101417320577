const Support = () => import(/* webpackChunkName: "support-index" */ '@/pages/support/index')

const SupportTickets = () => import(/* webpackChunkName: "support-tickets" */ '@/pages/support/tickets/index')
const ListSupportTickets = () => import(/* webpackChunkName: "list-support-tickets" */ '@/pages/support/tickets/list')
const CreateSupportTicket = () => import(/* webpackChunkName: "create-support-ticket" */ '@/pages/support/tickets/create')
const EditSupportTicket = () => import(/* webpackChunkName: "edit-support-ticket" */ '@/pages/support/tickets/edit')

export default [
    {
        path: '/support',
        component: Support,
        meta: {
            breadcrumb: 'breadcrumb.support.title',
            breadcrumb_to: {name: 'support.tickets.list' }
        },
        children: [
            {path: '', redirect: {name: 'support.tickets.list'}},
            {
                path: 'tickets',
                component: SupportTickets,
                meta: {
                    breadcrumb: 'breadcrumb.support.tickets.title',
                    breadcrumb_to: {name: 'support.tickets.list' }
                },
                children: [
                    {
                        path: 'list',
                        name: 'support.tickets.list',
                        component: ListSupportTickets,
                        meta: {
                            breadcrumb: 'breadcrumb.support.tickets.list'
                        }
                    },
                    {
                        path: 'create',
                        name: 'support.tickets.create',
                        component: CreateSupportTicket,
                        meta: {
                            breadcrumb: 'breadcrumb.support.tickets.create'
                        }
                    },
                    {
                        path: ':id/edit',
                        name: 'support.tickets.edit',
                        component: EditSupportTicket,
                        meta: {
                            breadcrumb: 'breadcrumb.support.tickets.edit'
                        }
                    },
                ]
            },
        ]
    },
]
