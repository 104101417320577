import SpaceRoutes from './spaces'
import ContainerRoutes from './container'
import DomainRoutes from './domains'
import BillingRoutes from './billing'
import GeneralRoutes from './general'
import IntegrationsRoutes from './integrations'
import ProjectRoutes from './projects'
import RouteRoutes from './routes'
// import ServiceRoutes from './services'
import DatabaseRoutes from './databases'
import SettingsRoutes from './settings'
import SupportRoutes from './support'
import AuthRoutes from './auth'

export default [
    ...AuthRoutes,
    ...SpaceRoutes,
    ...DomainRoutes,
    ...BillingRoutes,
    ...GeneralRoutes,
    ...IntegrationsRoutes,
    ...ProjectRoutes,
    ...RouteRoutes,
    // ...ServiceRoutes,
    ...SettingsRoutes,
    ...ContainerRoutes,
    ...SupportRoutes,
    ...DatabaseRoutes
]
