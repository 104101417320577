<template>
    <v-form @submit.prevent="submit">
        <v-text-field
            label="E-Mail"
            type="email"
            v-model="form.email"
            :error="error('email')"
            :error-messages="errorMessages('email')"
        />
        <v-text-field
            label="Passwort"
            type="password"
            v-model="form.password"
            :error="error('password')"
            :error-messages="errorMessages('password')"
        />
        <v-btn type="submit"
               block
               large
               class="mt-4"
               :loading="loading"
               color="primary"
        >
            Login
        </v-btn>
    </v-form>
</template>


<script>
    import {mapActions} from "vuex";
    import FormErrors from "@/mixins/FormErrors";
    import getEnv from "@/plugins/env";

    export default {
        name: "login-form",

        mixins: [
            FormErrors
        ],

        data: () => ({
            form: {
                email: "",
                password: ""
            },
            loading: false
        }),

        methods: {
            ...mapActions({
                'fetchUser': "auth/fetchUser"
            }),
            async submit() {
                if (this.loading) {
                    return
                }

                this.loading = true
                this.resetErrors()
                try {
                    await axios.get(getEnv('VUE_APP_SANCTUM_CSRF_URL'));
                    await axios.post(`login`, this.form)
                    await this.fetchUser();
                    this.form = {email: "", password: ""};
                    this.$emit('logged-in');
                } catch(e) {
                    this.formErrorsFromResponse(e)
                } finally {
                    this.loading = false
                }
            }
        }
    }
</script>
