export default {
    methods: {
        websiteUrl(uri) {
            let url = process.env.VUE_APP_WEBSITE_URL + `/${this.$root.$i18n.locale}`
            if (uri) {
                url += `/${uri}`
            }
            return url
        }
    }
}
