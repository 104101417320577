<template>
  <div>
    <v-content>
        <v-container>
          <router-view></router-view>
        </v-container>
    </v-content>
  </div>
</template>

<script>
  import AppHeader from "../components/AppHeader";

  export default {
    name: "empty",
    components: {
      AppHeader
    }
  }
</script>
