import * as types from '../mutation-types'

// state
export const state = {
  containers: { data: []},
  currentContainer: {},
  currentContainerPod: {},
}

// getters
export const getters = {
  containers: state => state.containers,
  currentContainer: state => state.currentContainer,
  currentContainerPod: state => state.currentContainerPod,
  containerById: state => (id) => {
    if ( ! state.containers.hasOwnProperty('data') || !state.containers.data.length > 0) {
      return null
    }

    return _.keyBy(state.containers.data, 'id')[id]
  }
}

// mutations
export const mutations = {
  [types.SET_CONTAINERS] (state, payload) {
    state.containers = payload
  },
  [types.SET_CURRENT_CONTAINER] (state, payload) {
    state.currentContainer = payload
  },
  [types.SET_CURRENT_CONTAINER_POD] (state, payload) {
    state.currentContainerPod = payload
  },
}

// actions
export const actions = {
  setCurrentContainer ({ commit }, payload) {
    commit(types.SET_CURRENT_CONTAINER, payload)
  },
  setCurrentContainerPod ({ commit }, payload) {
    commit(types.SET_CURRENT_CONTAINER_POD, payload)
  }

}
