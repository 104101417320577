<template>
    <div>
        <v-content>
            <v-container>
                <v-layout class="d-flex justify-center" style="min-height: calc(100vh - 50px)">

                    <div class="d-flex flex-column" style="min-height: 90vh">
                        <div class="d-flex justify-center pb-8 pt-8">
                            <img :src="lay7LogoSrc" alt="LAY7 logo" style="height: 50px;">
                        </div>

                        <v-spacer/>

                        <router-view/>

                        <v-spacer/>

                        <div class="register-bottom-bar pt-8">
                            <v-row no-gutters>
                                <v-col cols="12">
                                    <span class="copyright-text">Copyright © {{ year }} LAY7 cloud</span>
                                </v-col>
                                <v-col cols="12">
                                    <div class="links-right">
                                        <a :href="websiteUrl()" target="_blank">{{ $t('Homepage') }}</a>
                                        <a :href="websiteUrl('terms')" target="_blank">{{ $t('Terms') }}</a>
                                        <a :href="websiteUrl('privacy')" target="_blank">{{ $t('Privacy') }}</a>
                                        <a :href="websiteUrl('imprint')" target="_blank">{{ $t('Imprint') }}</a>
                                    </div>
                                </v-col>
                            </v-row>
                        </div>

                    </div>

                </v-layout>
            </v-container>
        </v-content>
    </div>
</template>

<script>
import {mapActions} from "vuex";
import UrlBuilder from "@/mixins/UrlBuilder";
import Utils from "@/mixins/Utils";

export default {
    name: "guest-centered",

    mixins: [
        UrlBuilder,
        Utils
    ],

    computed: {
        year() {
            return new Date().getFullYear()
        },
    },
    mounted() {
        this.setDarkMode(false)
    },
    methods: {
        ...mapActions({
            setDarkMode: "ui/setDarkMode"
        }),
    }
}
</script>
<style lang="scss">
.register-bottom-bar {
    text-align: center;

    .copyright-text {
        font-size: 12px;
    }

    a {
        font-size: 12px;
        margin: 0 7px;
    }
}
.register-top-bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100px;

    .logo {
        max-width: 200px;
        margin: 10px auto;
        display: block;
    }

    .registration-header {
        text-align: right;
    }
}
@media (min-width: 961px) {
    .register-top-bar {
        .logo {
            margin: 10px 0;
        }
    }
}
</style>
