import * as types from '../mutation-types'

// state
export const state = {
  user: null,
  limits: {},
  loginModalOpen: false
}

// getters
export const getters = {
  user: state => state.user,
  limits: state => state.limits,
  loginModalOpen: state => state.loginModalOpen
}

// mutations
export const mutations = {
  [types.FETCH_USER_SUCCESS] (state, { user }) {
    state.user = user
  },

  [types.FETCH_USER_LIMITS] (state, { limits }) {
    state.limits = limits
  },

  [types.UPDATE_USER] (state, { user }) {
    state.user = user
  },

  [types.LOGIN_MODAL_OPEN] (state) {
    state.loginModalOpen = true
  },
}

// actions
export const actions = {
  async fetchUser ({ commit }) {
    try {
      const { data: {data} } = await axios.get('account/user')
      commit(types.FETCH_USER_SUCCESS, { user: data })
    } catch (e) {
      if (e.response.status === 401) {
        commit(types.LOGIN_MODAL_OPEN)
      }
    }

  },
  async fetchUserLimits ({ commit }) {
      const { data } = await axios.get('account/limits')
      commit(types.FETCH_USER_LIMITS, { limits: data })
    return data
  },

  updateUser ({ commit }, payload) {
    commit(types.UPDATE_USER, payload)
  },
}
