<template>
    <div>
        <v-row v-if="!ready" justify="center">
            <div class="mt-5">
                <v-progress-circular
                        indeterminate
                ></v-progress-circular>
            </div>
        </v-row>

        <v-list v-if="ready" dense>
            <template v-for="item in items">
                <navigation-item v-bind="item"></navigation-item>
            </template>
        </v-list>
    </div>
</template>

<script>
    import NavigationItem from "./item";
    export default {
        name: "main-nav",
        components: {NavigationItem},

        async created()
        {
            this.ready = true
        },

        data: () => ({
            services: [],
            ready: false
        }),

        computed: {
            items()
            {
                return [
                    ...this.generalItems,
                    ...this.applicationItems,
                    ...this.networkItems,
                    ...this.integrationsItems,
                    ...this.accountItems,
                    ...this.billingItems
                ]
            },
            generalItems()
            {
                return [
                    {
                        label: this.$t('Dashboard'),
                        icon: "mdi-home",
                        exact: true,
                        to: {name: 'dashboard'}
                    },
                    {
                        label: this.$t('Projects'),
                        icon: "mdi-folder",
                        exact: true,
                        to: {name: 'projects.list'}
                    },
                ]
            },
            applicationItems()
            {
                return [
                    {
                        type: "subheader",
                        label: this.$t('Apps')
                    },
                    {
                        label: this.$t('Spaces'),
                        icon: "mdi-language-php",
                        to: {name: 'spaces.list'}
                    },
                    {
                        label: this.$t('Databases'),
                        icon: "mdi-database",
                        to: {name: 'databases.list'}
                    },
                    // {
                    //     label: this.$t('navigation.apps'),
                    //     icon: "mdi-apps",
                    //     to: {name: 'spaces'}
                    // },
                    // {
                    //     label: this.$t('navigation.container'),
                    //     icon: "mdi-docker",
                    //     to: {name: 'container.list'}
                    // },
                ]
            },
            networkItems()
            {
                return [
                    {
                        type: "subheader",
                        label: this.$t('Routing')
                    },
                    {
                        label: this.$t('Domains'),
                        icon: "mdi-earth",
                        to: {name: 'domains.list'},
                        exact: true
                    },
                    {
                        label: this.$t('Routes'),
                        icon: "mdi-directions-fork",
                        to: {name: 'routes.list'}
                    },
                    {
                        label: this.$t('Cloud DNS'),
                        icon: "mdi-dns",
                        to: {name: 'domains.zones.list'},
                        exact: true
                    },
                ]
            },
            emailItems()
            {
                return [
                    {
                        type: "subheader",
                        label: this.$t('E-Mail')
                    },
                    {
                        label: this.$t('Mailboxes'),
                        icon: "mdi-email-outline",
                        to: {name: 'mailboxes.list'}
                    },
                    {
                        label: this.$t('Mail Redirects'),
                        icon: "mdi-email-send-outline",
                        to: {name: 'mail-forwards.list'}
                    },
                ]
            },
            billingItems()
            {
                return [
                    {
                        type: "subheader",
                        label: this.$t('Billing')
                    },
                    {
                        label: this.$t('Dashboard'),
                        icon: "mdi-view-dashboard",
                        to: {name: 'billing.dashboard'}
                    },
                    {
                        label: this.$t('Invoices'),
                        icon: "mdi-receipt",
                        to: {name: 'billing.invoices.list'}
                    },
                ]
            },
            accountItems()
            {
                return [
                    {
                        type: "subheader",
                        label: this.$t('Account')
                    },
                    {
                        label: this.$t('SSH Keys'),
                        icon: "mdi-ssh",
                        to: {name: 'settings.sshKeys'}
                    },
                    {
                        label: this.$t('Support'),
                        icon: "mdi-help",
                        to: {name: 'support.tickets.list'}
                    },
                    {
                        label: this.$t('Settings'),
                        icon: "mdi-cog-outline",
                        to: {name: 'settings.profile'}
                    }
                ]
            },
            integrationsItems()
            {
                return [
                    {
                        type: "subheader",
                        label: this.$t('Integrations')
                    },
                    // {
                    //     label: this.$t('navigation.git-connect'),
                    //     icon: "mdi-git",
                    //     to: {name: 'integrations.git.accounts'}
                    // },
                    {
                        label: this.$t('API Tokens'),
                        icon: "mdi-lock",
                        to: {name: 'integrations.api-tokens.list'}
                    },
                    // {
                    //     label: "Container-Registries",
                    //     icon: "mdi-docker",
                    //     to: {name: 'integrations.containerRegistries.list'}
                    // }
                ]
            }
        },

        methods: {
        }
    }
</script>
