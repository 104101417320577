const Spaces = () => import(/* webpackChunkName: "spaces-index" */ '@/pages/spaces/index')
const ListSpaces = () => import(/* webpackChunkName: "list-spaces" */ '@/pages/spaces/list')
const CreateSpace = () => import(/* webpackChunkName: "create-space" */ '@/pages/spaces/create')
const EditSpace = () => import(/* webpackChunkName: "edit-space" */ '@/pages/spaces/edit/index')

const EditSpaceCronjobs = () => import(/* webpackChunkName: "edit-space-cronjobs-index" */ '@/pages/spaces/edit/cronjobs/index')
const EditSpaceCronjobsList = () => import(/* webpackChunkName: "edit-space-cronjobs-list" */ '@/pages/spaces/edit/cronjobs/list')
const EditSpaceCronjobsAdd = () => import(/* webpackChunkName: "edit-space-cronjobs-add" */ '@/pages/spaces/edit/cronjobs/add')
const EditSpaceCronjobsEdit = () => import(/* webpackChunkName: "edit-space-cronjobs-edit" */ '@/pages/spaces/edit/cronjobs/edit')

const EditSpaceOptionsGeneral = () => import(/* webpackChunkName: "edit-space-options-general" */ '@/pages/spaces/edit/options-general')
const EditSpaceOptionsWebserver = () => import(/* webpackChunkName: "edit-space-options-webserver" */ '@/pages/spaces/edit/options-webserver')
const EditSpaceOptionsSsh = () => import(/* webpackChunkName: "edit-space-options-ssh" */ '@/pages/spaces/edit/options-ssh')
const EditSpaceOptionsPhp = () => import(/* webpackChunkName: "edit-space-options-php" */ '@/pages/spaces/edit/options-php')
const EditSpaceOptionsPerformance = () => import(/* webpackChunkName: "edit-space-options-performance" */ '@/pages/spaces/edit/options-performance')
const EditSpaceOptionsStorage = () => import(/* webpackChunkName: "edit-space-options-storage" */ '@/pages/spaces/edit/options-storage')
const EditSpaceOptionsAdvanced = () => import(/* webpackChunkName: "edit-space-options-advanced" */ '@/pages/spaces/edit/options-advanced')

export default [
    {
        path: '/spaces',
        component: Spaces,
        meta: {
            breadcrumb: 'breadcrumb.spaces.title',
            breadcrumb_to: {name: 'spaces.list'}
        },
        children: [
            {path: '', redirect: {name: 'spaces.list'}},
            {
                path: 'list',
                name: 'spaces.list',
                component: ListSpaces,
                meta: {
                    breadcrumb: 'breadcrumb.spaces.list'
                }
            },
            {
                path: 'create',
                name: 'spaces.create',
                component: CreateSpace,
                meta: {
                    breadcrumb: 'breadcrumb.spaces.create'
                }
            },
            {
                path: ':space_id/edit',
                name: 'spaces.edit',
                component: EditSpace,
                meta: {
                    breadcrumb: 'breadcrumb.spaces.edit.title',
                    breadcrumb_to: {name: 'spaces.edit.options.general'}
                },
                redirect: {name: "spaces.edit.options.general"},
                children: [
                    {
                        path: 'general',
                        name: 'spaces.edit.options.general',
                        component: EditSpaceOptionsGeneral,
                        meta: {
                            breadcrumb: 'breadcrumb.spaces.edit.options.general'
                        }
                    },
                    {
                        path: 'webserver',
                        name: 'spaces.edit.options.webserver',
                        component: EditSpaceOptionsWebserver,
                        meta: {
                            breadcrumb: 'breadcrumb.spaces.edit.options.webserver'
                        }
                    },
                    {
                        path: 'php',
                        name: 'spaces.edit.options.php',
                        component: EditSpaceOptionsPhp,
                        meta: {
                            breadcrumb: 'breadcrumb.spaces.edit.options.php'
                        }
                    },
                    {
                        path: 'performance',
                        name: 'spaces.edit.options.performance',
                        component: EditSpaceOptionsPerformance,
                        meta: {
                            breadcrumb: 'breadcrumb.spaces.edit.options.performance'
                        }
                    },
                    {
                        path: 'storage',
                        name: 'spaces.edit.options.storage',
                        component: EditSpaceOptionsStorage,
                        meta: {
                            breadcrumb: 'breadcrumb.spaces.edit.options.storage'
                        }
                    },
                    {
                        path: 'advanced',
                        name: 'spaces.edit.options.advanced',
                        component: EditSpaceOptionsAdvanced,
                        meta: {
                            breadcrumb: 'breadcrumb.spaces.edit.options.advanced'
                        }
                    },
                    {
                        path: 'ssh',
                        name: 'spaces.edit.ssh',
                        component: EditSpaceOptionsSsh,
                        meta: {
                            breadcrumb: 'breadcrumb.spaces.edit.ssh'
                        }
                    },
                    {
                        path: 'cronjobs',
                        name: 'spaces.edit.cronjobs',
                        component: EditSpaceCronjobs,
                        redirect: {name: "spaces.edit.cronjobs.list"},
                        meta: {
                            breadcrumb: 'breadcrumb.spaces.edit.cronjobs.title'
                        },
                        children: [
                            {
                                path: '',
                                name: 'spaces.edit.cronjobs.list',
                                component: EditSpaceCronjobsList,
                                meta: {
                                    breadcrumb: 'breadcrumb.spaces.edit.cronjobs.list'
                                }
                            },
                            {
                                path: 'add',
                                name: 'spaces.edit.cronjobs.add',
                                component: EditSpaceCronjobsAdd,
                                meta: {
                                    breadcrumb: 'breadcrumb.spaces.edit.cronjobs.add'
                                }
                            },
                            {
                                path: ':cronjob_id/edit',
                                name: 'spaces.edit.cronjobs.edit',
                                component: EditSpaceCronjobsEdit,
                                meta: {
                                    breadcrumb: 'breadcrumb.spaces.edit.cronjobs.edit'
                                }
                            }
                        ]
                    }
                ]
            }
        ]
    },
]
