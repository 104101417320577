const Databases = () => import(/* webpackChunkName: "databases-index" */ '@/pages/databases/index')
const ListDatabases = () => import(/* webpackChunkName: "list-databases" */ '@/pages/databases/list')
const CreateDatabase = () => import(/* webpackChunkName: "create-database" */ '@/pages/databases/create')
const EditDatabase = () => import(/* webpackChunkName: "edit-database" */ '@/pages/databases/edit/index')

const EditDatabaseOptionsGeneral = () => import(/* webpackChunkName: "edit-database-options-general" */ '@/pages/databases/edit/options-general')
const EditDatabaseOptionsPerformance = () => import(/* webpackChunkName: "edit-database-options-performance" */ '@/pages/databases/edit/options-performance')
const EditDatabaseOptionsStorage = () => import(/* webpackChunkName: "edit-database-options-storage" */ '@/pages/databases/edit/options-storage')

export default [
    {
        path: '/databases',
        component: Databases,
        meta: {
            breadcrumb: 'breadcrumb.databases.title',
            breadcrumb_to: {name: 'databases.list'}
        },
        children: [
            {path: '', redirect: {name: 'databases.list'}},
            {
                path: 'list',
                name: 'databases.list',
                component: ListDatabases,
                meta: {
                    breadcrumb: 'breadcrumb.databases.list'
                }
            },
            {
                path: 'create',
                name: 'databases.create',
                component: CreateDatabase,
                meta: {
                    breadcrumb: 'breadcrumb.databases.create'
                }
            },
            {
                path: ':database_id/edit',
                name: 'databases.edit',
                component: EditDatabase,
                meta: {
                    breadcrumb: 'breadcrumb.databases.edit.title',
                    breadcrumb_to: {name: 'databases.edit.options.general'}
                },
                redirect: {name: "databases.edit.options.general"},
                children: [
                    {
                        path: 'general',
                        name: 'databases.edit.options.general',
                        component: EditDatabaseOptionsGeneral,
                        meta: {
                            breadcrumb: 'breadcrumb.databases.edit.options.general'
                        }
                    },
                    {
                        path: 'performance',
                        name: 'databases.edit.options.performance',
                        component: EditDatabaseOptionsPerformance,
                        meta: {
                            breadcrumb: 'breadcrumb.databases.edit.options.performance'
                        }
                    },
                    {
                        path: 'storage',
                        name: 'databases.edit.options.storage',
                        component: EditDatabaseOptionsStorage,
                        meta: {
                            breadcrumb: 'breadcrumb.databases.edit.options.storage'
                        }
                    },
                ]
            }
        ]
    },
]
