<template>
    <v-container>
        <v-row>
            <v-col>
                <slot></slot>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "default-container"
    }
</script>
