import * as types from '../mutation-types'

// state
export const state = {
  currentDomain: {}
}

// getters
export const getters = {
  currentDomain: state => state.currentDomain,
}

// mutations
export const mutations = {
  [types.SET_CURRENT_DOMAIN] (state, payload) {
    state.currentDomain = payload
  }
}

// actions
export const actions = {
  setCurrentDomain ({ commit }, payload) {
    commit(types.SET_CURRENT_DOMAIN, payload)
  }
}
