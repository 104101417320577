export default {
    data: () => ({
        formErrors: {}
    }),

    methods: {
        error(key)
        {
            return this.formErrors.hasOwnProperty(key);
        },
        errorMessages(key)
        {
            return this.formErrors[key]
        },
        errorCount(key)
        {
            return this.error(key) ? this.formErrors[key].length : 0;
        },
        formErrorsFromResponse(response) {
            this.formErrors = response.data.errors;
        },
        resetErrors()
        {
            this.formErrors = {}
        },
    }
}
