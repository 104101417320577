import * as types from '../mutation-types'

// state
export const state = {
  spaces: { data: []},
  currentSpace: {}
}

// getters
export const getters = {
  spaces: state => state.spaces,
  currentSpace: state => state.currentSpace,
  spaceById: state => (id) => {
    if ( ! state.spaces.hasOwnProperty('data') || !state.spaces.data.length > 0) {
      return null
    }

    return _.keyBy(state.spaces.data, 'id')[id]
  }
}

// mutations
export const mutations = {
  [types.SET_SPACES] (state, payload) {
    state.spaces = payload
  },
  [types.SET_CURRENT_SPACE] (state, payload) {
    state.currentSpace = payload
  }
}

// actions
export const actions = {
  setCurrentSpace ({ commit }, payload) {
    commit(types.SET_CURRENT_SPACE, payload)
  }

}
