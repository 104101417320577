const Container = () => import(/* webpackChunkName: "container-index" */ '@/pages/container/index')
const ListContainer = () => import(/* webpackChunkName: "list-container" */ '@/pages/container/list')
const CreateContainer = () => import(/* webpackChunkName: "create-container" */ '@/pages/container/create')
const EditContainer = () => import(/* webpackChunkName: "edit-container" */ '@/pages/container/edit/index')

const EditContainerOptionsGeneral = () => import(/* webpackChunkName: "edit-container-options-general" */ '@/pages/container/edit/options/general')
const EditContainerOptionsEnv = () => import(/* webpackChunkName: "edit-container-options-env" */ '@/pages/container/edit/options/env')
const EditContainerOptionsArgs = () => import(/* webpackChunkName: "edit-container-options-args" */ '@/pages/container/edit/options/args')
const EditContainerOptionsPorts = () => import(/* webpackChunkName: "edit-container-options-ports" */ '@/pages/container/edit/options/ports')
const EditContainerOptionsVolumes = () => import(/* webpackChunkName: "edit-container-options-volumes" */ '@/pages/container/edit/options/volumes')

const ContainerPod = () => import(/* webpackChunkName: "container-pod" */ '@/pages/container/pod/index')

const ContainerPodOptionsGeneral = () => import(/* webpackChunkName: "container-pod-options-general" */ '@/pages/container/pod/index')

const ContainerPodJobs = () => import(/* webpackChunkName: "container-pod-jobs" */ '@/pages/container/pod/jobs/index')
const ContainerPodJobsList = () => import(/* webpackChunkName: "container-pod-jobs-list" */ '@/pages/container/pod/jobs/list')
const CreateContainerPodJob = () => import(/* webpackChunkName: "container-pod-jobs-create" */ '@/pages/container/pod/jobs/create')
const EditContainerPodJob = () => import(/* webpackChunkName: "container-pod-jobs-edit" */ '@/pages/container/pod/jobs/edit')

const ContainerPodVolumes = () => import(/* webpackChunkName: "container-pod-volumes" */ '@/pages/container/pod/volumes/index')
const ContainerPodVolumesList = () => import(/* webpackChunkName: "list-container-pod-volumes" */ '@/pages/container/pod/volumes/list')
const CreateContainerPodVolume = () => import(/* webpackChunkName: "create-container-pod-volume" */ '@/pages/container/pod/volumes/create')
const EditContainerPodVolume = () => import(/* webpackChunkName: "edit-container-pod-volume" */ '@/pages/container/pod/volumes/edit')

export default [
    {
        path: '/container',
        component: Container,
        meta: {
            breadcrumb: 'breadcrumb.container.title',
            breadcrumb_to: {name: 'container.list'}
        },
        children: [
            {path: '', redirect: {name: 'container.list'}},
            {
                path: 'list',
                name: 'container.list',
                component: ListContainer,
                meta: {
                    breadcrumb: 'breadcrumb.container.list'
                }
            },
            {
                path: 'create/:container_pod_id?',
                name: 'container.create',
                component: CreateContainer,
                meta: {
                    breadcrumb: 'breadcrumb.container.create'
                }
            },
            {
                path: "pod/:container_pod_id",
                meta: {
                    breadcrumb: 'breadcrumb.container.pod.title',
                    breadcrumb_to: {name: 'container.pod.options.general'}
                },
                component: ContainerPod,
                children: [
                    {
                        path: "general",
                        name: "container.pod.options.general",
                        component: ContainerPodOptionsGeneral,
                        meta: {
                            breadcrumb: 'breadcrumb.container.pod.options.general'
                        }
                    },
                    {
                        path: 'volumes',
                        name: 'container.pod.volumes',
                        component: ContainerPodVolumes,
                        meta: {
                            breadcrumb: 'breadcrumb.container.pod.volumes.title',
                            breadcrumb_to: {name: 'container.pod.volumes.list'}
                        },
                        redirect: {name: "container.pod.volumes.list"},
                        children: [
                            {
                                path: 'list',
                                name: 'container.pod.volumes.list',
                                component: ContainerPodVolumesList,
                                meta: {
                                    breadcrumb: 'breadcrumb.container.pod.volumes.list'
                                }
                            },
                            {
                                path: 'create',
                                name: 'container.pod.volumes.create',
                                component: CreateContainerPodVolume,
                                meta: {
                                    breadcrumb: 'breadcrumb.container.pod.volumes.create'
                                }
                            },
                            {
                                path: ':volume_id/edit',
                                name: 'container.pod.volumes.edit',
                                component: EditContainerPodVolume,
                                meta: {
                                    breadcrumb: 'breadcrumb.container.pod.volumes.edit'
                                }
                            },
                        ]
                    },
                    {
                        path: 'jobs',
                        name: 'container.pod.jobs',
                        component: ContainerPodJobs,
                        meta: {
                            breadcrumb: 'breadcrumb.container.pod.jobs.title',
                            breadcrumb_to: {name: 'container.pod.jobs.list'}
                        },
                        redirect: {name: "container.pod.jobs.list"},
                        children: [
                            {
                                path: 'list',
                                name: 'container.pod.jobs.list',
                                component: ContainerPodJobsList,
                                meta: {
                                    breadcrumb: 'breadcrumb.container.pod.jobs.list'
                                }
                            },
                            {
                                path: 'create',
                                name: 'container.pod.jobs.create',
                                component: CreateContainerPodJob,
                                meta: {
                                    breadcrumb: 'breadcrumb.container.pod.jobs.create'
                                }
                            },
                            {
                                path: ':job_id/edit',
                                name: 'container.pod.jobs.edit',
                                component: EditContainerPodJob,
                                meta: {
                                    breadcrumb: 'breadcrumb.container.pod.jobs.edit'
                                }
                            },
                        ]
                    },
                ]
            },
            {
                path: ':id/edit',
                name: 'container.edit',
                component: EditContainer,
                meta: {
                    breadcrumb: 'breadcrumb.container.edit.title',
                    breadcrumb_to: {name: 'container.edit.options.general'}
                },
                redirect: {name: "container.edit.options.general"},
                children: [
                    {
                        path: 'general',
                        name: 'container.edit.options.general',
                        component: EditContainerOptionsGeneral,
                        meta: {
                            breadcrumb: 'breadcrumb.container.edit.options.general'
                        }
                    },
                    {
                        path: 'env',
                        name: 'container.edit.options.env',
                        component: EditContainerOptionsEnv,
                        meta: {
                            breadcrumb: 'breadcrumb.container.edit.options.env'
                        }
                    },
                    {
                        path: 'ports',
                        name: 'container.edit.options.ports',
                        component: EditContainerOptionsPorts,
                        meta: {
                            breadcrumb: 'breadcrumb.container.edit.options.ports'
                        }
                    },
                    {
                        path: 'args',
                        name: 'container.edit.options.args',
                        component: EditContainerOptionsArgs,
                        meta: {
                            breadcrumb: 'breadcrumb.container.edit.options.args'
                        }
                    },
                    {
                        path: 'volumes',
                        name: 'container.edit.options.volumes',
                        component: EditContainerOptionsVolumes,
                        meta: {
                            breadcrumb: 'breadcrumb.container.edit.options.volumes'
                        }
                    },
                ]
            }
            // {
            //     path: 'create',
            //     name: 'spaces.create',
            //     component: CreateSpace,
            //     meta: {
            //         breadcrumb: 'breadcrumb.spaces.create'
            //     }
            // },

        ]
    },
]
