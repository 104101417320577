const Projects = () => import(/* webpackChunkName: "projects-index" */ '@/pages/projects/index')
const ListProjects = () => import(/* webpackChunkName: "list-projects" */ '@/pages/projects/list')
const CreateProject = () => import(/* webpackChunkName: "create-project" */ '@/pages/projects/create')
const EditProject = () => import(/* webpackChunkName: "edit-project" */ '@/pages/projects/edit')

export default [
    {
        path: "/projects",
        component: Projects,
        meta: {
            breadcrumb: "breadcrumb.projects.title",
            breadcrumb_to: {name: 'projects.list'}
        },
        children: [
            {
                path: "",
                redirect: {name: "projects.list"}
            },
            {
                path: 'list',
                name: 'projects.list',
                component: ListProjects,
                meta: {
                    breadcrumb: 'breadcrumb.projects.list'
                }
            },
            {
                path: 'create',
                name: 'projects.create',
                component: CreateProject,
                meta: {
                    breadcrumb: 'breadcrumb.projects.create'
                }
            },
            {
                path: ':project_id/edit',
                name: 'projects.edit',
                component: EditProject,
                meta: {
                    breadcrumb: 'breadcrumb.projects.edit'
                }
            }
        ]
    }
]
