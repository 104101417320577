import * as types from '../mutation-types'

// state
export const state = {
  serviceItems: [],
  currentServiceItem: {}
}

// getters
export const getters = {
  serviceItems: state => state.serviceItems,
  currentServiceItem: state => state.currentServiceItem
}

// mutations
export const mutations = {
  [types.UPDATE_SERVICE_ITEMS] (state, payload) {
    state.serviceItems = payload
  },
  [types.SET_CURRENT_SERVICE_ITEM] (state, payload) {
    state.currentServiceItem = payload
  },
}

// actions
export const actions = {
  updateServiceItems ({ commit }, payload) {
    commit(types.UPDATE_SERVICE_ITEMS, payload)
  },
  setCurrentServiceItem({commit}, payload) {
    commit(types.SET_CURRENT_SERVICE_ITEM, payload)
  }
}


