<template>
    <div id="app">
        <v-app>
            <component v-if=" !loading && !blockedByDialog"
                       :is="layout"
            />
            <v-row v-if="loading"
                   align="center"
                   justify="center"
            >
                <v-progress-circular indeterminate />
            </v-row>
            <ask-dialog ref="ask-dialog" />

            <login-dialog ref="login-dialog" />
            <email-verification-dialog ref="email-verification-dialog" />
        </v-app>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import AskDialog from "@/dialogs/AskDialog";
import Vue from 'vue'
import LoginDialog from "@/dialogs/LoginDialog";
import EmailVerificationDialog from "@/dialogs/EmailVerificationDialog.vue";

// Load layout components dynamically.
const requireContext = require.context('@/layouts', true, /.*\.vue$/)

const layouts = requireContext.keys()
    .map(file =>
        [file.replace(/(^.\/)|(\.vue$)/g, ''), requireContext(file)]
    )
    .reduce((components, [name, component]) => {
        components[name] = component.default || component
        return components
    }, {})

export default {
    components: {
        EmailVerificationDialog,
        LoginDialog,
        AskDialog,
        ...layouts
    },

    metaInfo: {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: 'Dashboard',
        // all titles will be injected into this template
        titleTemplate: '%s | LAY 7'
    },

    async created() {
        this.$vuetify.theme.dark = this.darkMode === true
    },

    async mounted() {
        this.loadUser()
        Vue.prototype.$ask = (question) => this.$refs['ask-dialog'].ask(question)
        Vue.prototype.$setLocale = (locale) => this.setLocale(locale)
        Vue.prototype.$openLoginDialog = (pushToDashboard) => this.openLoginDialog(pushToDashboard)
        window.vueInstance = this
    },

    data: () => ({
        loading: true,
        defaultLayout: 'default',
        blockedByDialog: false,
    }),

    computed: {
        ...mapGetters({
            darkMode: "ui/darkMode",
            user: "auth/user",
            loginModalOpen: "auth/loginModalOpen"
        }),
        currentLocale: {
            get() {
                return this.$root.$i18n.locale
            },
            set(locale) {
                this.$root.$i18n.locale = locale
            }
        },
        layout() {
            return this.$route?.meta?.layout || 'default'
        }
    },

    methods: {
        ...mapActions({
            'fetchUser': "auth/fetchUser"
        }),
        async loadUser() {
            this.loading = true

            try {
                await this.fetchUser()
                this.currentLocale = this.user.language || 'en'
                this.$store.dispatch('projects/loadProjectsForSelect')
            } catch (e) {

            } finally {
                this.loading = false
            }
        },
        async setLocale(locale) {
            this.loading = true
            try {
                await axios.put('users/' + this.user.id, {
                    language: locale
                })
                this.currentLocale = locale
                await this.fetchUser()
            } catch (e) {
                console.error(e)
            } finally {
                this.loading = false
            }
        },
        async openLoginDialog(pushToDashboard = false) {
            if (this.$route.meta.auth === false) {
                return
            }
            if (pushToDashboard && (this.$route.name !== 'dashboard')) {
                await this.$router.push({name: 'dashboard'})
            }

            this.blockedByDialog = true
            this.$refs['login-dialog']
                .open()
                .loggedIn(() => {
                    this.loading = false
                    this.blockedByDialog = false
                })
        },
        async openEmailVerificationDialog() {
            if (this.$route.path.indexOf('email/verify') !== -1) {
                return
            }
            if (this.$route.meta.auth === false) {
                return
            }

            this.$refs['email-verification-dialog'].open()
            this.blockedByDialog = true
        }
    },

    watch: {
        darkMode(val) {

            this.$vuetify.theme = {
                dark: val
            }
        },
        loginModalOpen(val) {
            if (val) {
                this.openLoginDialog()
            }
        },
        '$route'(route) {
            if (route.meta.auth === false) {
                this.blockedByDialog = false

                this.$nextTick(() => {
                    this.$refs['login-dialog'].close()
                })
            }
        }
    }
}
</script>
