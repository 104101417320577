// ui.js
export const SET_MAIN_NAV_OPEN = "SET_MAIN_NAV_OPEN"
export const SET_DARK_MODE = "SET_DARK_MODE"

// auth.js
export const SAVE_TOKEN = 'SAVE_TOKEN'
export const FETCH_USER = 'FETCH_USER'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_LIMITS = 'FETCH_USER_LIMITS'
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE'
export const UPDATE_USER = 'UPDATE_USER'
export const LOGIN_MODAL_OPEN = 'LOGIN_MODAL_OPEN'

// lang.js
export const SET_LOCALE = 'SET_LOCALE'

// contracts.js
export const SET_CONTRACTS = 'SET_CONTRACTS'

// invoices.js
export const SET_INVOICES = 'SET_INVOICES'

// shop.js
export const SET_SHOP_PRODUCTS = 'SET_SHOP_PRODUCTS'
export const SET_SHOP_CATEGORIES = 'SET_SHOP_CATEGORIES'
export const ADD_TO_SHOP_CART = 'ADD_TO_SHOP_CART'
export const REMOVE_FROM_SHOP_CART = 'REMOVE_FROM_SHOP_CART'

// spaces.js
export const SET_SPACES = 'SET_SPACES'
export const SET_CURRENT_SPACE = 'SET_CURRENT_SPACE'

// domain.js
export const SET_CURRENT_DOMAIN = 'SET_CURRENT_DOMAIN'

// databases.js
export const SET_CURRENT_DATABASE = 'SET_CURRENT_DATABASE'
export const SET_DATABASES = 'SET_DATABASES'

// container.js
export const SET_CONTAINERS = 'SET_CONTAINERS'
export const SET_CURRENT_CONTAINER = 'SET_CURRENT_CONTAINER'
export const SET_CURRENT_CONTAINER_POD = 'SET_CURRENT_CONTAINER_POD'

// projects.js
export const SET_PROJECTS = 'SET_PROJECTS'
export const SET_PROJECTS_FOR_SELECT = 'SET_PROJECTS_FOR_SELECT'
export const SET_CURRENT_PROJECT = 'SET_CURRENT_PROJECT'

// services.js
export const UPDATE_SERVICE_ITEMS = 'UPDATE_SERVICE_ITEMS'
export const SET_CURRENT_SERVICE_ITEM = 'SET_CURRENT_SERVICE_ITEM'

// route.js
export const SET_ROUTES = 'SET_ROUTES'
