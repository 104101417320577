const Settings = () => import(/* webpackChunkName: "settings-index" */ '@/pages/settings/index')
const ProfileSettings = () => import(/* webpackChunkName: "profile-settings" */ '@/pages/settings/profile')
const SshKeysSettings = () => import(/* webpackChunkName: "ssh-keys-settings" */ '@/pages/settings/sshKeys')

export default [
    {
        path: '/settings',
        component: Settings,
        meta: {
            breadcrumb: 'breadcrumb.settings.title',
            breadcrumb_to: {name: 'settings.profile'}
        },
        children: [
            {path: '', redirect: {name: 'settings.profile'}},
            {
                path: 'profile',
                name: 'settings.profile',
                component: ProfileSettings,
                meta: {
                    breadcrumb: 'breadcrumb.settings.profile'
                }
            },
            {
                path: 'ssh-keys',
                name: 'settings.sshKeys',
                component: SshKeysSettings,
                meta: {
                    breadcrumb: 'breadcrumb.settings.sshKeys'
                }
            }
        ]
    }
]
