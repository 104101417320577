import axios from 'axios'
import * as types from '../mutation-types'

// state
export const state = {
  mainNavOpen: false,
  darkMode: false,
}

// getters
export const getters = {
  mainNavOpen: state => state.mainNavOpen,
  darkMode: state => state.darkMode,
}

// mutations
export const mutations = {
  [types.SET_MAIN_NAV_OPEN] (state, payload) {
    state.mainNavOpen = Boolean(payload)
  },

  [types.SET_DARK_MODE] (state, payload) {
    state.darkMode = Boolean(payload)
  }
}

// actions
export const actions = {

  // main nav
  toggleMainNavOpen({ state, commit })
  {
    commit(types.SET_MAIN_NAV_OPEN, !state.mainNavOpen)
  },
  setMainNavOpen({ state, commit }, payload)
  {
    commit(types.SET_MAIN_NAV_OPEN, payload)
  },
  openMainNav({commit})
  {
    commit(types.SET_MAIN_NAV_OPEN, true)
  },
  closeMainNav({commit})
  {
    commit(types.SET_MAIN_NAV_OPEN, false)
  },

  // dark mode
  toggleDarkMode({ state, commit })
  {
    commit(types.SET_DARK_MODE, !state.darkMode)
  },
  setDarkMode({ state, commit }, payload)
  {
    commit(types.SET_DARK_MODE, payload)
  },
  activateDarkMode({commit})
  {
    commit(types.SET_DARK_MODE, true)
  },
  deactivateDarkMode({commit})
  {
    commit(types.SET_DARK_MODE, false)
  }

}
