import * as types from '../mutation-types'

// state
export var state = {
  items: []
}

// getters
export const getters = {
  routes: state => state.items
}

// mutations
export const mutations = {
  [types.SET_ROUTES] (state, payload) {
    state.items = payload
  }
}

// actions
export const actions = {

  async getRoutes ({ commit }) {
    const response = await axios.get('/api/routes')
    return response
  }

}
