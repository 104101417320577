const Routes = () => import(/* webpackChunkName: "routes-index" */ '@/pages/routes/index')
const ListRoutes = () => import(/* webpackChunkName: "list-routes" */ '@/pages/routes/list')
const CreateRoute = () => import(/* webpackChunkName: "create-route" */ '@/pages/routes/create')
const EditRoute = () => import(/* webpackChunkName: "edit-route" */ '@/pages/routes/edit')

export default [
    {
        path: "/routes",
        component: Routes,
        meta: {
            breadcrumb: "breadcrumb.routes.title",
            breadcrumb_to: {name: 'routes.list'}
        },
        children: [
            {
                path: "",
                redirect: {name: "routes.list"}
            },
            {
                path: 'list',
                name: 'routes.list',
                component: ListRoutes,
                meta: {
                    breadcrumb: 'breadcrumb.routes.list'
                }
            },
            {
                path: 'create',
                name: 'routes.create',
                component: CreateRoute,
                meta: {
                    breadcrumb: 'breadcrumb.routes.create'
                }
            },
            {
                path: ':project_id/edit',
                name: 'routes.edit',
                component: EditRoute,
                meta: {
                    breadcrumb: 'breadcrumb.routes.edit'
                }
            }
        ]
    }
]
