
<template>
    <div>
        <v-subheader v-if="isSubheader">
            {{ label }}
        </v-subheader>

        <v-list-item v-else :to="to" :exact="exact">
            <v-list-item-action>
                <v-icon v-html="icon"></v-icon>
            </v-list-item-action>
            <v-list-item-content>
                <v-list-item-title>
                    {{ label }}
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
    </div>
</template>

<script>
    export default {
        name: "navigation-item",

        props: {
            type: {
                type: String,
                default: "nav-item"
            },
            label: {
                type: String,
                default: ""
            },
            icon: {
                type: String,
                default: ""
            },
            to: {
                type: Object,
                default: () => {}
            },
            exact: {
                type: Boolean,
                default: false
            }
        },

        computed: {
            isSubheader()
            {
                return this.type === 'subheader'
            }
        }
    }
</script>
