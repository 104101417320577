<template>
    <v-dialog v-model="open" persistent width="600px">
        <v-card>
            <v-card-title :class="titleClass">
                {{ titleText }}
            </v-card-title>
            <v-card-text>
                {{ question }}
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text
                       @click="cancelClicked"
                       v-if="! cancelButtonHidden"
                >
                    {{ $t('cancel') }}
                </v-btn>
                <v-btn text @click="okClicked"
                       :class="okButtonClass"
                >
                    {{ textForOkButton }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: "ask-dialog",

        data: () => ({
            open: false,
            cancelButtonHidden: false,
            titleText: "",
            titleClass: "",
            question: "",
            okButtonText: null,
            okButtonClass: "",
            onOk: () => (null),
            onCancel: () => (null),
            onHidden: () => (null)
        }),

        computed: {
            textForOkButton() {
                return this.okButtonText ?? this.$t('yes')
            }
        },

        methods: {
            ask(question) {
                this.question = question
                this.open = true
                return this
            },
            title(val, titleClass = "") {
                this.titleText = val
                this.titleClass = titleClass
                return this
            },
            ok(val) {
                this.onOk = val
                return this
            },
            okText(val, okClass = "") {
                this.okButtonText = val
                this.okButtonClass = okClass
                return this
            },
            cancel(val) {
                this.onCancel = val
                return this
            },
            hideCancel() {
                this.cancelButtonHidden = true
                return this
            },
            cancelClicked() {
                this.onCancel()
                this.open = false
                this.reset()
                return this
            },
            okClicked() {
                this.onOk()
                this.open = false
                this.reset()
                return this
            },
            reset() {
                this.titleText = ""
                this.okButtonText = null
                this.question = ""
                this.cancelButtonHidden = false
                this.onOk = () => (null)
                this.onCancel = () => (null)
            }
        }
    }
</script>
