import * as types from '../mutation-types'

// state
export const state = {
  databases: { data: []},
  currentDatabase: {}
}

// getters
export const getters = {
  databases: state => state.databases,
  currentDatabase: state => state.currentDatabase,
  databaseById: state => (id) => {
    if ( ! state.databases.hasOwnProperty('data') || !state.databases.data.length > 0) {
      return null
    }

    return _.keyBy(state.databases.data, 'id')[id]
  }
}

// mutations
export const mutations = {
  [types.SET_DATABASES] (state, payload) {
    state.databases = payload
  },
  [types.SET_CURRENT_DATABASE] (state, payload) {
    state.currentDatabase = payload
  }
}

// actions
export const actions = {
  setCurrentDatabase ({ commit }, payload) {
    commit(types.SET_CURRENT_DATABASE, payload)
  }

}
