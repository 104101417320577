<template>
    <v-menu
            left
            bottom
    >
        <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" color="primary">
                <v-icon>mdi-account-circle</v-icon>
            </v-btn>
        </template>

        <v-list>
            <v-list-item @click.stop>
                <v-switch
                        v-model="darkMode"
                        label="Dark-Mode"
                ></v-switch>
            </v-list-item>
            <v-list-item :to="{name: 'settings.profile'}">
                <v-list-item-title>
                    Account
                </v-list-item-title>
            </v-list-item>
            <v-list-item @click.prevent="logout">
                <v-list-item-title>
                    Logout
                </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex'
    import store from "@/store";
    import {LOGIN_MODAL_OPEN} from "@/store/mutation-types";

    export default {
        name: "user-nav",

        computed: {
            ...mapGetters({
                getDarkMode: "ui/darkMode",
            }),
            darkMode: {
                get() {
                    return this.getDarkMode
                },
                set(value) {
                    return this.setDarkMode(value)
                }
            }
        },

        methods: {
            ...mapActions({
                setDarkMode: "ui/setDarkMode"
            }),
            async logout () {
                // Log out the user.
                await axios.post('logout')
                this.$openLoginDialog(true)
            },
        }
    }
</script>
